
export const accountTypes = [
    "Developer",
    "User"
]
export const applicationStates = {
    pending: "PENDNIG",
    approved: "APPROVED",
    rejected: "REJECTED",
    deleted: "DELETED",
}
